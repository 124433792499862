import { render, staticRenderFns } from "./CardType2.vue?vue&type=template&id=428c3af2&scoped=true&"
import script from "./CardType2.vue?vue&type=script&lang=js&"
export * from "./CardType2.vue?vue&type=script&lang=js&"
import style0 from "./CardType2.vue?vue&type=style&index=0&id=428c3af2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "428c3af2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VIcon,VImg})
